const initialState = {
    isDarkMode: false,
    cart: [],
    cartId: ""
}

//action
const TOGGLE_DARKMODE = 'TOGGLE_DARKMODE';
const ADD_ITEM = 'ADD_ITEM'
const UPDATE_ITEM = "UPDATE_ITEM"

export const toggleDarkMode = isDarkMode => ({
    type: TOGGLE_DARKMODE, isDarkMode
})

export const addItem = cart => ({
  type: ADD_ITEM, cart
})

export const updateCart = cart => ({
  type: UPDATE_ITEM, cart
})

//reducer
const reducer = (state = initialState, action) => {
    switch (action.type) {
      case TOGGLE_DARKMODE:
        return { ...state, isDarkMode: action.isDarkMode };
      case ADD_ITEM:
        return {...state, cart: action.cart};
      case UPDATE_ITEM:
         return {...state, cart: action.cart}
      default:
        return state;
    }
  };

  export default reducer;